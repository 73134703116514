<template>
  <v-container class="Product_bar">
    <v-row>
      <v-col cols="4">
        <h3 class="System_admin_text_Product ml-2">OFFERS</h3>
      </v-col>

      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>DEAL: </b>
            <span
              ><b>{{ this.currentDeal }}</b></span
            ></span
          >
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7">
        <div class="text-right">
          <b-button-group>
            <!-- <b-button @click="openTasks" class="tasks-button">Tasks</b-button> -->
            <b-button @click="openUploads" class="uploads-button"
              >Uploads</b-button
            >
            <b-button @click="openWorksheets" class="worksheets-button"
              >WorkSheets</b-button
            >
            <b-button @click="getData()" class="showall-button">
              <template slot="button-content">
                <b-icon icon="arrow-up"></b-icon> Features
              </template>
              Show All</b-button
            >
            <b-button @click="openBuyerSelect()" class="offers-button"
              >Limit Buyer</b-button
            >
            <b-button @click="getAgreedData()" class="purple-button"
              >Only Agreed</b-button
            >
            <b-button @click="getOpenData()" class="blue-button"
              >Only Open</b-button
            >
            <b-button
              v-if="userRole !== '3'"
              @click="openEdit"
              class="add-button"
              >Add</b-button
            >
          </b-button-group>
        </div>
      </v-col>
    </v-row>

    <v-col cols="12" class="customer-search-table">
      <v-data-table
        :headers="headers"
        :items="items"
        dense
        class="elevation-1 cursor-pointer"
      >
        <template v-slot:item.buyer_id="props">
          <v-edit-dialog
            :return-value.sync="props.item.buyer"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.buyer }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="Buyer"
                v-model="props.item.buyer_id"
                :items="list_user"
                item-value="id"
                item-text="text"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.offered_by_id="props">
          <v-edit-dialog
            :return-value.sync="props.item.offeredby"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.offeredby }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="Offered By"
                v-model="props.item.offered_by_id"
                :items="list_user"
                item-value="id"
                item-text="text"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.category_id="props">
          <v-edit-dialog
            :return-value.sync="props.item.category"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.category }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="Offer Terms"
                v-model="props.item.category_id"
                :items="list_category"
                item-value="id"
                item-text="text"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.amt="props">
          <v-edit-dialog
            :return-value.sync="props.item.amt"
            @save="saveEditDialog(props.item)"
          >
            {{ usCurrency.format(props.item.amt) }}
            <template v-slot:input>
              <money
                class="v-input--hide-details"
                label="Amount"
                v-model="props.item.amt"
              ></money>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.description="props">
          <v-edit-dialog
            :return-value.sync="props.item.description"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.description }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="Description/Terms"
                v-model="props.item.description"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.disposition="props">
          <v-edit-dialog
            :return-value.sync="props.item.disposition"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.disposition }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="Disposition"
                v-model="props.item.disposition"
                :items="list_dispo"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="currentUser === item.buyer_id"
            color="warning"
            small
            class="mr-5"
            @click="editedItems(item)"
            >fas fa-edit</v-icon
          >
          <v-icon
            v-else-if="currentUser === 1"
            color="warning"
            small
            class="mr-5"
            @click="editedItems(item)"
            >fas fa-edit</v-icon
          >

          <v-icon
            v-if="currentUser === item.buyer_id"
            color="error"
            small
            @click="deleteItem(item.id)"
            >fas fa-trash-alt</v-icon
          >

          <v-icon
            v-else-if="currentUser === 1"
            color="error"
            small
            @click="deleteItem(item.id)"
            >fas fa-trash-alt</v-icon
          >

          <v-icon
            v-if="userRole === '3' && item.buyer_type === 'Buyer1'"
            color="error"
            small
            @click="openSellerResponse(item.id)"
            >fas fa-reply</v-icon
          >
          <v-icon
            v-else-if="userRole === '3' && item.buyer_type === 'Buyer2'"
            color="error"
            small
            @click="openSellerResponse(item.id)"
            >fas fa-reply</v-icon
          >
          <v-icon
            v-else-if="userRole === '3' && item.buyer_type === 'Broker'"
            color="error"
            small
            @click="openSellerResponse(item.id)"
            >fas fa-reply</v-icon
          >
        </template>
      </v-data-table>
    </v-col>

    <v-row>
      <v-col cols="12">
        <div class="header-add-systemAdmin" style="float: left">
          <b-button class="send-button" @click="openSendBrokerEmails"
            >Send Broker Emails
          </b-button>
        </div>
        <div class="header-add-systemAdmin" style="float: left">
          <b-button class="completed-button" @click="openSendSellerEmails"
            >Send Seller Emails
          </b-button>
        </div>
        <div class="header-add-systemAdmin" style="float: right">
          <b-button class="back-button" @click="goBack">Back </b-button>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogEdit" max-width="600" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeEdit">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>{{ editedItem.id ? "Update Offer" : "Add Offer" }}</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-select
                            style="text-align: center"
                            label="Buyer"
                            v-model="editedItem.buyer_id"
                            :items="list_user"
                            item-value="id"
                            item-text="text"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-select
                            class="v-input--hide-details"
                            label="Offered By"
                            v-model="editedItem.offered_by_id"
                            :items="list_user"
                            item-value="id"
                            item-text="text"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-autocomplete
                            class="v-input--hide-details"
                            label="Offer Terms"
                            v-model="editedItem.category_id"
                            :items="list_category"
                            item-value="id"
                            item-text="text"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <label for="Amt">Amount</label>
                          <money
                            class="v-input--hide-details"
                            label="Amount"
                            v-model="editedItem.amt"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></money>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-textarea
                            style="text-align: center"
                            label="Description/Terms"
                            v-model="editedItem.description"
                            outlined
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <template>
                            <div>
                              <p></p>
                              <label>Offer Date</label>
                              <b-form-datepicker
                                id="start-datepicker"
                                v-model="editedItem.offer_date"
                                class="mb-2"
                                placeholder="Select Offer Date"
                              ></b-form-datepicker>
                            </div>
                          </template>
                        </v-col>
                      </v-row>

                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="saveFormData">
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSeller" max-width="600" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeSeller">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>{{ "Seller Offer Response" }}</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-select
                            style="text-align: center"
                            label="Seller"
                            v-model="editedItem.buyer_id"
                            :items="list_user"
                            item-value="id"
                            item-text="text"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-autocomplete
                            class="v-input--hide-details"
                            label="Offer Terms"
                            v-model="editedItem.category_id"
                            :items="list_category"
                            item-value="id"
                            item-text="text"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <label for="Amt">Amount</label>
                          <money
                            class="v-input--hide-details"
                            label="Amount"
                            v-model="editedItem.amt"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></money>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-textarea
                            style="text-align: center"
                            label="Description/Terms"
                            v-model="editedItem.description"
                            outlined
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-select
                            class="v-input--hide-details"
                            label="Disposition"
                            v-model="editedItem.disposition"
                            :items="list_dispo"
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <template>
                            <div>
                              <p></p>
                              <b-form-datepicker
                                id="start-datepicker"
                                v-model="editedItem.offer_date"
                                class="mb-2"
                                placeholder="Date"
                              ></b-form-datepicker>
                            </div>
                          </template>
                        </v-col>
                      </v-row>

                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="saveFormData">
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBuyer" max-width="300" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeBuyer">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>{{ "Select Buyer" }}</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-select
                            style="text-align: center"
                            v-model="buyerItem.buyer_id"
                            label="Buyer"
                            :items="list_buyer"
                            item-value="id"
                            item-text="text"
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="filterBuyer">
                            Select Buyer
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <div id="modal-yes-no">
      <b-modal
        id="modal-center"
        v-model="dlgModal"
        centered
        :title="errorTitle"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-row class="mb-1 text-center">
          <b-col cols="12"></b-col>
          <b-col>{{ errorText }}</b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-right"></p>
            <b-button-group class="float-right">
              <b-button @click="getBrokerList()" class="offers-button"
                >Yes</b-button
              >
              <b-button @click="dlgModal = false" class="back-button"
                >No</b-button
              >
            </b-button-group>
          </div>
        </template>
      </b-modal>
    </div>

    <div id="modal-seller">
      <b-modal
        id="modal-seller"
        v-model="dlgModalSeller"
        centered
        :title="errorTitle"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-row class="mb-1 text-center">
          <b-col cols="12"></b-col>
          <b-col>{{ errorText }}</b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-right"></p>
            <b-button-group class="float-right">
              <b-button @click="getSellerList()" class="offers-button"
                >Yes</b-button
              >
              <b-button @click="dlgModal = false" class="back-button"
                >No</b-button
              >
            </b-button-group>
          </div>
        </template>
      </b-modal>
    </div>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";
import { Money } from "v-money";

function formatAsCurrency(value, dec) {
  dec = dec || 0;
  if (value === null) {
    return 0;
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export default {
  components: { Money },

  created() {
    // this.checkPermissions();
    this.logActivity("Opened", this.editedItem.deal_id);

    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    CommonServices.listWithParams(this.componentURL, this.items)
      .then((response) => {
        console.log("Table Items: ", response.results);
        this.items = response.results;
        //localStorage.setItem('Deal', this.items[0].deal)
        console.log("currentDeal: ", this.currentDeal);
        console.log("currentUser: ", this.currentUser);
        let codes = response.results;
        codes.map((code) =>
          this.list_buyer.push({ id: code.buyer_id, text: code.buyer })
        );
        console.log("Buyers: ", this.list_buyer);
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("OfferCategories")
      .then((res) => {
        console.log("OfferCategories", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_category.push({ id: code.id, text: code.offer_category })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Users")
      .then((res) => {
        console.log("Users", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_user.push({
            id: code.id,
            text: code.first_name + " " + code.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: `Offers/?deal_id=${this.$route.params.id}`,
      componentURL_base: `Offers`,
      componentURL_agreed: `Offers/?deal_id=${this.$route.params.id}&disposition=Agreed`,
      componentURL_open: `Offers/?deal_id=${this.$route.params.id}&disposition=PENDING`,

      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      valid: false,
      dialog: false,
      dlgModal: false,
      dlgModalSeller: false,
      dialogDelete: false,
      dialogEdit: false,
      dialogSeller: false,
      dialogBuyer: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      currentDeal: localStorage.getItem("Deal"),
      currentUser: parseInt(localStorage.getItem("userid")),
      userRole: localStorage.getItem("role"),
      selectedBuyer: "",

      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",

      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: " ",
        precision: 0,
        masked: false,
      },

      usCurrency: null,

      headers: [
        { text: "ACTIONS", value: "actions", sortable: false },
        { text: "OFFER #", value: "id", sortable: true },
        { text: "DATE", value: "offer_date", sortable: true },
        {
          text: "BUYER/SELLER",
          value: "buyer_id",
          width: "20%",
          sortable: true,
        },
        { text: "TYPE", value: "buyer_type", sortable: true },
        {
          text: "CATEGORY",
          value: "category_id",
          width: "20%",
          sortable: true,
        },
        { text: "AMOUNT", value: "amt", sortable: true },
        { text: "DESCRIPTION/TERMS", value: "description", sortable: true },
        { text: "DISPOSITION", value: "disposition", sortable: true },
      ],

      items: [],
      brokerItems: [],
      sellerItems: [],

      list_category: [],
      list_user: [],
      list_dispo: ["Counter", "Agreed", "Rejected"],
      list_buyer: [],
      list_seller: [],

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "DealOffers",
        action: "",
        deal_id: "",
        json: "",
      },

      editedItem: {
        id: "",
        deal_id: this.$route.params.id,
        buyer_id: this.currentUser,
        offered_by_id: this.currentUser,
        offered_to_id: "",
        category_id: "",
        amt: "",
        description: null,
        disposition: "",
        offer_date: this.$moment(String(Date())).format("MM-DD-YYYY"),
      },

      buyerItem: {
        buyer_id: "",
      },
    };
  },

  computed: {
    amtFormatted: {
      get: function () {
        return formatAsCurrency(this.editedItem.amt, 0);
      },
      set: function (newValue) {
        this.editedItem.amt = Number(newValue.replace(/[^0-9.]/g, ""));
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    openSendBrokerEmails() {
      this.dlgModal = true;
      this.errorTitle = "Send Broker Emails?";
      this.errorText =
        "This will send an email to all Brokers who have pending offers.\nContinue?";
      this.headerBgVariant = "success";
      this.footerBgVariant = "success";
    },

    sendBrokerEmails() {
      this.brokerItems.forEach((item) => this.sendBrokerEmail(item));
      this.dlgModal = false;
    },

    async sendBrokerEmail(item) {
      console.log("Sending Broker email...", item.broker, item.broker_email);
      await CommonServices.postData("/Twillio/send-email", {
        user_name: item.broker,
        to_email: item.broker_email,
        subject: "PETERSON CLIENT OFFERS HISTORY UPDATE",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",
        html_content:
          "<p>Dear " +
          item.broker +
          ",</p>\
          <p>Please find attached the listing of recent offers pending on your account.</p>\
          <p><strong>Please login to https://petersonclient.com/ to address these offers.</strong> </p>",
      });
    },

    getBrokerList() {
      this.brokerItems = [];
      CommonServices.listWithParams("vwBrokerEmailList")
        .then((response) => {
          this.brokerItems = response.results;
          console.log("brokerItems:", this.brokerItems);
          this.sendBrokerEmails();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error retrieving broker list... ";
          console.log(error);
        });
    },

    openSendSellerEmails() {
      this.dlgModalSeller = true;
      this.errorTitle = "Send Seller Emails?";
      this.errorText =
        "This will send an email to all Sellers who have pending offers.\nContinue?";
      this.headerBgVariant = "success";
      this.footerBgVariant = "success";
    },

    sendSellerEmails() {
      this.sellerItems.forEach((item) => this.sendSellerEmail(item));
      this.dlgModalSeller = false;
    },

    async sendSellerEmail(item) {
      console.log(
        "Sending seller email...",
        item.first_name,
        item.last_name,
        item.email
      );
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${item.first_name || "New"} ${item.last_name || "User"}`,
        to_email: item.email,
        subject: "PETERSON CLIENT PENDING UPLOADS",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",
        html_content:
          "<p>Dear " +
          item.first_name +
          " " +
          item.last_name +
          ",</p>\
          <p>Please find attached the listing of uploads pending on your account.</p>\
          <p><strong>Please login to https://petersonclient.com/ to address these uploads.</strong> </p>",
      });
    },

    getSellerList() {
      CommonServices.listWithParams("vwSellersMissingUploads")
        .then((response) => {
          this.sellerItems = response.results;
          console.log("sellerItems:", this.sellerItems);
          this.sendSellerEmails();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error retrieving seller list... ";
          console.log(error);
        });
    },

    // checkPermissions() {
    //   if (this.isAdmin == "false") this.$router.push("/");
    // },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    openBuyerSelect() {
      this.dialogBuyer = true;
    },

    openEdit() {
      this.dialogEdit = true;
      this.editedItem.length = 0;
      this.editedItem.buyer_id = this.currentUser;
      this.editedItem.offered_by_id = this.currentUser;
      this.editedItem.offer_date = this.$moment(String(Date())).format(
        "YYYY-MM-DD"
      );
      console.log("Current User:", this.currentUser);
      console.log("Edited User:", this.editedItem);
    },

    openSellerResponse() {
      this.dialogSeller = true;
      this.editedItem.length = 0;
      this.editedItem.buyer_id = this.currentUser;
      this.editedItem.offer_date = this.$moment(String(Date())).format(
        "YYYY-MM-DD"
      );
      console.log("Current User:", this.currentUser);
      console.log("Edited User:", this.editedItem);
    },

    openTasks() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openBlindAd() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/BlindAdWorksheet/${this.$route.params.id}`);
    },

    openWorksheets() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/WorkSheets/`);
    },

    openUploads() {
      localStorage.setItem("DealID", this.$route.params.id);
      //First write any missing Deal Folders to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.$route.params.id}`);
          }, 500);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    filterBuyer() {
      this.selectedBuyer = this.buyerItem;
      this.getLimitBuyerData();
      this.closeBuyer();
    },

    getLimitBuyerData() {
      CommonServices.listWithParams(
        `Offers/?buyer_id=${this.buyerItem.buyer_id}&deal_id=${this.$route.params.id}`,
        this.items
      )
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAgreedData() {
      CommonServices.listWithParams(this.componentURL_agreed, this.items)
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getOpenData() {
      CommonServices.listWithParams(this.componentURL_open, this.items)
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "Offers"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Offers.xlsx");
      this.logActivity(
        "Exported to Excel",
        this.editedItem.deal_id,
        JSON.stringify(this.items)
      );
    },

    saveEditDialog(item) {
      CommonServices.updateData(this.componentURL, item).then((response) => {
        const index = this.items.findIndex((item) => item.id === response.id);
        this.$set(this.items, index, response);
      });
      this.snackbar = true;
      this.snackColor = "success";
      this.snackText = "Record successfully updated";
    },

    saveFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.offer_date) {
          this.editedItem.offer_date = this.$moment(
            String(this.editedItem.offer_date)
          ).format("MM/DD/YYYY");
        } else {
          this.editedItem.offer_date = null;
        }
        if (this.editedItem.id) {
          CommonServices.updateData(this.componentURL_base, this.editedItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.items, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
              this.logActivity(
                "Updated Form Data",
                this.editedItem.deal_id,
                JSON.stringify(this.editedItem)
              );
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        } else {
          CommonServices.addRecord(this.componentURL, this.editedItem)
            .then((response) => {
              this.items.push(response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.logActivity(
                "Added Offer Record",
                this.editedItem.deal_id,
                JSON.stringify(this.editedItem)
              );
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        }
        this.close();
        this.closeEdit();
        this.closeSeller();
      }
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      CommonServices.deleteRecord(this.componentURL_base, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          this.logActivity(
            "Deleted Record",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteItemSilently() {
      CommonServices.deleteRecord(this.componentURL_base, this.editedItem.id)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.getData();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    editedItems(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.offer_date = this.$moment(
        String(this.editedItem.offer_date)
      ).format("YYYY-MM-DD");
      this.dialogEdit = true;
    },

    sellerReponse(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.offer_date = this.$moment(
        String(this.editedItem.offer_date)
      ).format("YYYY-MM-DD");
      this.dialogEdit = true;
    },

    clearSearch() {
      this.searchText = "";
      this.getData();
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },

    resetItems() {
      this.editedItem = {};
    },

    closeBuyer() {
      this.$refs.form.reset();
      this.dialogBuyer = false;
    },

    closeSeller() {
      this.$refs.form.reset();
      this.dialogSeller = false;
    },

    closeEdit() {
      this.$refs.form.reset();
      this.resetItems();
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
button {
  color: white;
  border-radius: 2px;
  cursor: pointer;
  font-size: 13px;
}
.close-popup .v-image__image {
  width: 20px !important;
  object-fit: contain !important;
  background-size: contain !important;
}
.popup-wrap {
  padding: 40px 0;
}

.popup-title {
  padding: 0 50px;
  text-align: center;
}

.popup-title h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.close-popup.v-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0 !important;
}

.close-popup.v-btn > span + span {
  display: none !important;
}

.close-popup.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 !important;
  height: auto;
  width: auto;
  min-width: auto;
}

.close-popup.v-btn:before {
  content: none;
}

.offers-button {
  background-color: rgba(145, 9, 31, 255);
  margin-left: 0px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
}
.tasks-button {
  background-color: rgba(160, 152, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.uploads-button {
  background-color: rgba(81, 109, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blue-button {
  background-color: rgb(13, 13, 68);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.add-button {
  background-color: rgba(194, 88, 48, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.back-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.worksheets-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  width: 130px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.showall-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  color: black;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.completed-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 10px;
  margin-right: 0px;
  height: 36px;
  width: auto;
}
.send-button {
  background-color: green;
  border: none;
  margin-left: 10px;
  margin-right: 0px;
  height: 36px;
  width: auto;
}

.incomplete-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}
.purple-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.orange-button {
  background-color: rgba(194, 86, 47, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.biege-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blah-button {
  background-color: rgba(160, 152, 131, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}
input#file-input {
  display: none;
}

input#file-input + label {
  background-color: #0157a0;
  padding: 4px;
  color: #fff;
  border: 2px solid #85bdee;
  border-radius: 9px;
}

input#file-input + label:hover {
  background-color: #20e0a5;
  border-color: #fff;
  cursor: pointer;
}
.err-message {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: red;
  line-height: 0pt;
}

.small-lbl {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 0pt;
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}
</style>
